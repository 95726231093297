import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function HowSection() {
  return (
    <section className="how-section" id="how-section">
      <ul className="pagination">
        <li>
          <AnchorLink href="#hero-section">01</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#how-section" className="active">
            02
          </AnchorLink>
        </li>
        <li>
          <AnchorLink href="#price-section">03</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#our-section">04</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#info-section">05</AnchorLink>
        </li>
      </ul>
      <div className="container">
        <div className="title-box">
          <h2 className="title">
            How OaaSIS works
            <span className="title-accent">How OaaSIS works</span>
          </h2>
        </div>
        <h3 className="heading">
          <span className="heading-accent">Make your Startup </span>
          Success sure vie creating a Backbone of your organization!
        </h3>
        <div className="subtitle">
          Save Time& Efforts with OaaSiS support to Concentrate on your core
          Business Idea. <br />
          All your Start-up Business set up in on place:
        </div>
        <div className="how-card">
          <div className="how-card__circle">
            <i className="icon-settings"></i>
          </div>
          <div className="how-card__box">
            <div className="how-card__title">
              Software Development/ App & Web
            </div>
            <div className="how-card__text">
              We leverage our expertise to provide you with full
              guidance for your software architecture and organizational setup.
            </div>
          </div>
        </div>
        <div className="how-card">
          <div className="how-card__circle">
            <i className="icon-employee"></i>
          </div>
          <div className="how-card__box">
            <div className="how-card__title">
              Organization Set Up (HR& Structures, Processes)
            </div>
            <div className="how-card__text">
              Forget about figuring out how to avoid mistakes in
              staffing, scaling up your OPS workforces and creating workflows.
            </div>
          </div>
        </div>
        <div className="how-card">
          <div className="how-card__circle">
            <i className="icon-headphones"></i>
          </div>
          <div className="how-card__box">
            <div className="how-card__title">All around support</div>
            <div className="how-card__text">
              Avoid the risks of trial and error.
              We’ve been there already,
              and we know how to master this process and save you time.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
