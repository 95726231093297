import React, { useState } from "react";
import cx from "classnames";

import logo from "../assets/images/logo.svg";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="header">
      <nav className="header-nav">
        <div className="header-logo">
          <strong className="logo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </strong>
        </div>

        <ul className="header-menu-box">
          <li>
            <button
              className="header-menu-opener"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <i className="icon-burger"></i>
            </button>
            <ul className={cx("header-menu", { active: isMenuOpen })}>
              <li>
                <a href="/">
                  <span>What is OaaSIS</span>
                </a>
              </li>
              <li>
                <a href="/">
                  <span>Pricing</span>
                </a>
              </li>
              <li>
                <a href="/">
                  <span>About us</span>
                </a>
              </li>
              <li>
                <a href="/">
                  <span>FAQ</span>{" "}
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="header-auth">
          <li>
            <a href="/" className="header-auth__btn header-auth__btn--register">
              <i className="icon-register"></i>
              <span>Register</span>
            </a>
          </li>
          <li>
            <a href="/" className="header-auth__btn header-auth__btn--login">
              <i className="icon-login"></i>
              <span>Log in</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
