import React from "react";
import logo from "../assets/images/logo-light.svg";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <strong className="logo">
          <a href="/">
            <img src={logo} alt="logo"/>
          </a>
        </strong>

        <div className="footer-row">
          <div className="footer-col">
            <div className="footer-box">
              <div className="footer-title">
                <a href="/">About us</a>
              </div>
              <div className="footer-text">
                 With a community of over 400 million users (and a  majority age group being 18 to 29)
              </div>
            </div>

            <div className="footer-box">
              <div className="footer-title">
                <a href="/">Contact Us</a>
              </div>
              <div className="footer-text">
                <address>
                  4096 N Highland St, Arlington VA 32101, USA
                </address>
                <ul>
                  <li>
                    <a href="mailto:contributz@demolink.org">contributz@demolink.org</a>
                  </li>
                  <li>
                    <a href="tel:800 1234 56 78">800 1234 56 78</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-col fg">
            <div className="footer-subrow">
              <div className="footer-col">
                <div className="footer-box">
                  <div className="footer-title">
                    <a href="/">Information</a>
                  </div>
                  <ul className="footer-list">
                    <li><a href="/">About Us</a></li>
                    <li><a href="/">More Search</a></li>
                    <li><a href="/">Blog</a></li>
                    <li><a href="/">Events</a></li>
                  </ul>
                </div>
              </div>
              <div className="footer-col">
                <div className="footer-box">
                  <div className="footer-title">
                    <a href="/">Helpful Links</a>
                  </div>
                  <ul className="footer-list">
                    <li><a href="/">Servicer </a></li>
                    <li><a href="/">Supports</a></li>
                    <li><a href="/">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-col">
            <div className="footer-form">
              <div className="footer-title">More Info</div>
              <div className="input-box">
                <input type="email" className="form-control darken" placeholder="Enter Your Email"/>
              </div>
              <div className="btn-box">
                <button className="btn btn-lg btn-primary">Subscribe</button>
              </div>
            </div>
          </div>
        </div>

        <ul className="footer-socials">
          <li>
            <a href="/">
              <i className="icon-twitter"></i>
            </a>
          </li>
          <li>
            <a href="/">
              <i className="icon-facebook"></i>
            </a>
          </li>
          <li>
            <a href="/">
              <i className="icon-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
