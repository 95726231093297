import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function OurSection() {
  return (
    <section className="our-section" id="our-section">
      <ul className="pagination">
        <li>
          <AnchorLink href="#hero-section">01</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#how-section">02</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#price-section">03</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#our-section" className="active">
            04
          </AnchorLink>
        </li>
        <li>
          <AnchorLink href="#info-section">05</AnchorLink>
        </li>
      </ul>
      <div className="container">
        <span className="line vertical line-1"></span>
        <div className="title-box">
          <h2 className="title">
            our pricing
            <span className="title-accent">our pricing</span>
          </h2>
        </div>
        <h3 className="heading">
          <div className="heading-accent">Choose one of the programs </div>
          and let your Idea flourish
        </h3>
        <div className="our-row">
          <div className="our-col">
            <div className="our-card">
              <div className="our-card__text">
                <div className="our-card__tag">Price tag</div>
                <div className="our-card__title">Starter/ Consulting</div>
                <ul className="our-card__list">
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>IT project initiation</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Technology stack</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>System Architecture</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Organizations structure set up</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>HR Set-up</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Governance/ Management Structure</span>
                  </li>
                </ul>
              </div>
              <div className="our-card__btn">
                <button className="btn btn-lg btn-primary">Sign-up</button>
              </div>
            </div>
          </div>
          <div className="our-col our-col--main">
            <div className="our-card">
              <div className="our-card__text">
                <div className="our-card__tag">Price tag</div>
                <div className="our-card__title">Grower /Prototype</div>
                <ul className="our-card__list">
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Project Scoping</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Architectural blueprint</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>The prototype of the startup</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>
                      Organizational Preparation for Go Live & Go Live support
                    </span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Hyper Care support</span>
                  </li>
                </ul>
                <div className="our-card__link">
                  <a href="/">
                    + <br />
                    All Consulting features
                  </a>
                </div>
              </div>
              <div className="our-card__btn">
                <button className="btn btn-lg btn-primary">Sign-up</button>
              </div>
            </div>
          </div>
          <div className="our-col">
            <div className="our-card">
              <div className="our-card__text">
                <div className="our-card__tag">Price tag</div>
                <div className="our-card__title">Advanced/ Take-off</div>
                <ul className="our-card__list">
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Wiki for project</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Source code</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Project documentation</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Live MVP</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>HR SOS Support</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Operations management</span>
                  </li>
                </ul>
                <div className="our-card__link">
                  <a href="/">
                    + <br />
                    All Prototype features
                  </a>
                </div>
              </div>
              <div className="our-card__btn">
                <button className="btn btn-lg btn-primary">Sign-up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
