import React from "react";

import Header from "components/Header";
import MainSection from "components/MainSection";
import HowSection from "components/HowSection";
import FormSection from "components/FormSection";
import PriceSection from "components/PriceSection";
import OurSection from "components/OurSection";
import InfoSection from "components/InfoSection";
import Footer from "components/Footer";

import "./App.scss";

function App() {
  return (
    <>
      <Header />
      <main>
        <MainSection />
        <HowSection />
        <FormSection />
        <PriceSection />
        <OurSection />
        <InfoSection />
      </main>
      <Footer />
    </>
  );
}

export default App;
