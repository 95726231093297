import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function MainSection() {
  return (
    <section className="hero-section" id="hero-section">
      <span className="line vertical main-line"></span>
      <span className="line horizontal line-1"></span>
      <span className="line vertical line-2"></span>
      <ul className="pagination">
        <li>
          <AnchorLink href="#hero-section" className="active">
            01
          </AnchorLink>
        </li>
        <li>
          <AnchorLink href="#how-section">02</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#price-section">03</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#our-section">04</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#info-section">05</AnchorLink>
        </li>
      </ul>
      <div className="container">
        <div className="title-box">
          <h2 className="title">
            About us
            <span className="title-accent">About us</span>
          </h2>
        </div>
        <h1 className="hero-title">
          Organization as a Service & Information System OaaSIS for
          <div className="hero-title--accent">
            Start-Up 
            F
            <i className="icon-Group">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            unders
          </div>
        </h1>
        <ul className="hero-list">
          <li>
            <span>
              Take your time for your core Ideas & Products. 
            </span>
          </li>
          <li>
            <span>
              We make the launch of your start-up easy, safe, and transparent. 
            </span>
          </li>
          <li>
            <span>
              With us you have a simple & independent way to turn
              your idea into functionable organization & IT system.
            </span>
          </li>
          <li>
            <span>
              Develop your app/web, organizational and HR structure with us.
            </span>
          </li>
          <li>
            <span>
            Leverage our expertise in IT & HR to promote your own success.
            </span>
          </li>
        </ul>
        <div className="hero-link-box">
          <a href="/" className="hero-link">
            <span>CHECK OUR SUBSCRIPTION PLANS</span>
            <i className="icon-link"></i>
          </a>
        </div>
      </div>
    </section>
  );
}
