import React from "react";

export default function FormSection() {
  return (
    <section className="form-section">
      <div className="wrapper">
        <div className="container">
          <div className="form-title">
            Join us
          </div>
          <div className="form-subtitle">
            and gain instant added value
          </div>
        </div>
      </div>
      <div className="container">
        <div className="form-box">
          <div className="form-capture">
            Subscribe to our newsletter for the latest news, freebies, and exclusive content about OaaSIS. 
          </div>
          <div className="form-row">
            <div className="input-box">
              <input type="email" className="form-control form-control-md" placeholder="Enter Your Email"/>
            </div>
            <div className="btn-box">
              <button className="btn btn-lg btn-secondary">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
