import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import image1 from "../assets/images/info1.png";
import image2 from "../assets/images/info2.png";
import image3 from "../assets/images/info3.png";
import image4 from "../assets/images/info4.png";

export default function InfoSection() {
  return (
    <section className="info-section" id="info-section">
      <ul className="pagination">
        <li>
          <AnchorLink href="#hero-section">01</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#how-section">02</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#price-section">03</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#our-section">04</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#info-section" className="active">
            05
          </AnchorLink>
        </li>
      </ul>
      <div className="container">
        <div className="title-box">
          <h2 className="title">
            Information Systems
            <span className="title-accent">Information Systems</span>
          </h2>
        </div>
        <h3 className="heading">
          <div className="heading-accent">Join us&nbsp;</div>
          and gain instant added value
        </h3>

        <div className="info-grid">
          <div className="info-row">
            <div className="info-col">
              <div className="info-card">
                <div className="info-card__head">
                  <div className="info-card__icon">
                    <i className="icon-question"></i>
                  </div>
                  <div className="info-card__count">01</div>
                </div>
                <div className="info-card__title">Do you know</div>
                <div className="info-card__text">
                  that 60% of start-ups fail due to organizational and
                  bureaucratic issues.
                </div>
              </div>
            </div>
            <div className="info-col xs-none">
              <div
                className="info-card img-bg"
                style={{ backgroundImage: `url(${image1})` }}
              ></div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-col xs-none">
              <div
                className="info-card img-bg"
                style={{ backgroundImage: `url(${image2})` }}
              ></div>
            </div>
            <div className="info-col">
              <div className="info-card card--top">
                <div className="info-card__head">
                  <div className="info-card__icon">
                    <i className="icon-organization"></i>
                  </div>
                  <div className="info-card__count">02</div>
                </div>
                <div className="info-card__title">Organization</div>
                <div className="info-card__text">
                  as a Service and Information System (OaaSIS) is our
                  answer to 21st century start-up founders’ challenges.
                </div>
              </div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-col">
              <div className="info-card">
                <div className="info-card__head">
                  <div className="info-card__icon">
                    <i className="icon-folder"></i>
                  </div>
                  <div className="info-card__count">03</div>
                </div>
                <div className="info-card__title">Product</div>
                <div className="info-card__text">
                  In one product you get all you need to take-off
                  with your business: software, organization guidelines & set up,
                  as well as access to e2e business excellence experts.
                </div>
              </div>
            </div>
            <div className="info-col xs-none">
              <div
                className="info-card img-bg"
                style={{ backgroundImage: `url(${image3})` }}
              ></div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-col xs-none">
              <div
                className="info-card img-bg"
                style={{ backgroundImage: `url(${image4})` }}
              ></div>
            </div>
            <div className="info-col">
              <div className="info-card card--top">
                <div className="info-card__head">
                  <div className="info-card__icon">
                    <i className="icon-exit-full-screen"></i>
                  </div>
                  <div className="info-card__count">04</div>
                </div>
                <div className="info-card__title">Concentrate</div>
                <div className="info-card__text">
                  First-time founders can focus only on growing
                  the business, leaving time-consuming activities
                  behind and mitigating risk of business failure.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
