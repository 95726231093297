import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function PriceSection() {
  return (
    <section className="price-section" id="price-section">
      <span className="line horizontal line-1"></span>
      <span className="line vertical line-2"></span>
      <ul className="pagination">
        <li>
          <AnchorLink href="#hero-section">01</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#how-section">02</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#price-section" className="active">
            03
          </AnchorLink>
        </li>
        <li>
          <AnchorLink href="#our-section">04</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#info-section">05</AnchorLink>
        </li>
      </ul>
      <div className="container">
        <span className="line vertical line-3"></span>
        <div className="title-box">
          <h2 className="title">
            our pricing
            <span className="title-accent">our pricing</span>
          </h2>
        </div>
        <h3 className="heading">
          <div className="heading-accent">Monthly Subscription,</div>
          Cancel Anytime
        </h3>
        <div className="price-row">
          <div className="price-col">
            <div className="price-card">
              <div className="price-card__text">
                <div className="price-card__tag">Price tag</div>
                <div className="price-card__title">Starter</div>
                <ul className="price-card__list">
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Project Inception Service</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Startup Guide</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Organizational Setup Guide</span>
                  </li>
                </ul>
                <div className="price-card__capture">
                  You have your IT&Org Setup
                </div>
              </div>
              <div className="price-card__btn">
                <button className="btn btn-md btn-secondary">lear more</button>
              </div>
            </div>
          </div>
          <div className="price-col">
            <div className="price-card">
              <div className="price-card__text">
                <div className="price-card__tag">Price tag</div>
                <div className="price-card__title">Grower</div>
                <ul className="price-card__list">
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Software design</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Organizational prototype</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Consulting</span>
                  </li>
                </ul>
                <div className="price-card__capture">
                  You have your IT & Org. Prototype
                </div>
              </div>
              <div className="price-card__btn">
                <button className="btn btn-md btn-secondary">lear more</button>
              </div>
            </div>
          </div>
          <div className="price-col">
            <div className="price-card">
              <div className="price-card__text">
                <div className="price-card__tag">Price tag</div>
                <div className="price-card__title">Take-off</div>
                <ul className="price-card__list">
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Software MVP</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Organizational implementation</span>
                  </li>
                  <li>
                    <i className="icon-check-outline"></i>
                    <span>Prototype</span>
                  </li>
                </ul>
                <div className="price-card__capture">
                  You have your fully supported startup
                </div>
              </div>
              <div className="price-card__btn">
                <button className="btn btn-md btn-secondary">lear more</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
